import './Dashboard.css'
import React, { useState } from 'react'

import { Button, Layout } from 'antd';
import {MenuUnfoldOutlined, MenuFoldOutlined} from '@ant-design/icons'

import Sidebar from './Sidebar';
import CustomHeader from './Header';
import DashboardContent from './ContentComponents/DashboardContent/DashboardContent';
import DraftingContent from './ContentComponents/DraftingContent/DraftingContent';
import LegalSearchContent from './ContentComponents/LegalSearchContent/LegalSearchContent'
import DataExtractionContent from './ContentComponents/DataExtractionContent/DataExtractionContent';
import DocReviewConten from './ContentComponents/DocReviewContent/DocReviewConten';

const {Sider, Header, Content} = Layout;

function Index() {
    const [collapsed, setCollapesd] = useState(false);
    const [selectedMenuItem, setSelectedMenuItem] = useState('1');

    const renderContent = () => {
        switch (selectedMenuItem) {
            case '1':
                return <DashboardContent setSelectedMenuItem={setSelectedMenuItem} />;
            case '2':
                return <LegalSearchContent />;
            case '3':
                return <DraftingContent />
            case '4':
                return <DataExtractionContent />;
            case '5':
                return <DocReviewConten />;
            default:
                return ;
        }
    };

  return (
    <div className='Dashboard-container'>
        <Layout>
            
            <Sider 
              theme='light' 
              trigger={null} 
              collapsible 
              collapsed={collapsed} 
              className='db-slider'
            >
            
                <Sidebar selectedMenuItem={ selectedMenuItem } setSelectedMenuItem={setSelectedMenuItem}/>
            
                <Button 
                  type='text' 
                  icon={ collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/> }
                  onClick={() => setCollapesd(!collapsed)}
                  className='db-triger-btn'
                />
            
            </Sider>
            
            <Layout>
            
                <Header className='db-header'>
                    <CustomHeader/>
                </Header>
            
                <Content className='db-content'>
                    
                {/* Render content based on Selected menu*/}
                {renderContent()}


                </Content>
            
            </Layout>
        
        </Layout>
    </div>
  )
}

export default Index
