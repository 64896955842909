import React, { useState } from 'react'
import styles from './DraftingForm.module.css'

import { handleError, handleSuccess } from 'Pages/Utils';
import { ToastContainer } from 'react-toastify';
import ResultForms from '../ResultForms/ResultForms';


import documentTypes from 'assets/Doc_type.json';
import ActList from 'assets/acts_list.json';
import { Select } from 'antd';
const { Option } = Select;


function CaseSearchForm() {

  const [resultData, setResultData] = useState(null);
  const [showResult, setShowResult] = useState(false);  
  const [loading, setLoading] = useState(false)
  // const [generateLoading, setGenerateLoading] = useState(false);
    const [formData, setFormData] = useState({
        caseDetails: '',
        act: '',
        doctype: []
      });
    

      const handleChange = async (value, name) => {
  
        setFormData((prevData) => ({
          ...prevData,
          [name]: value
        }));
        console.log(formData);
      };



      const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formData);
        setLoading(true);
      // API CALL
          try {
            const url = process.env.REACT_APP_FCAPI ? `${process.env.REACT_APP_FCAPI}/Drafting/query` : 'http://localhost:8080/Drafting/query';
            
            const headers = {
              'Authorization': localStorage.getItem('token'),
              'Content-Type': 'application/json'
          };

          
          const body = JSON.stringify({
            caseDetails: formData.caseDetails,
            act: formData.act,
            doctype : formData.doctype
          });
            
          // see the res data

            const response = await fetch(url, {
              method: 'POST',
              headers: headers,
              body: body
          });
          const result = await response.json(); 
      
          if (result.success) {
            handleSuccess(result.message);
            
            setResultData(result.doc);
            setShowResult(true);

          } else {
            handleError(result.message);
          }

          } catch (err) {
            handleError(err);
          } finally {
            setLoading(false);
          }

        
      }

      const handleBack = () => {
        setShowResult(false);
        // Clear the form fields
        setFormData({
          caseDetails: '',
          act: '',
          doctype: []
        });
        
      };


      //generate document search function
      // const [searchTerm, setSearchTerm] = useState('');
      
      // const handleSearchChange = (e) => {
      //   setSearchTerm(e.target.value);

      // };


        /////////////////////////////////////////////////////////genrate custom form////////////////////
      // const handleSearch = async () => {
      //   // Implement your search logic here
      //   setGenerateLoading(true);
      //     try {
      //     const url = process.env.REACT_APP_FCAPI
      //       ? `${process.env.REACT_APP_FCAPI}/generate_document`
      //       : 'http://localhost:8080/generate_document'; // Adjust this endpoint as needed

      //     const headers = {
      //       'Authorization': localStorage.getItem('token'),
      //     };

      //     const response = await fetch(url, {
      //       method: 'POST',
      //       headers: headers,
      //       body: JSON.stringify({ searchTerm }) // Send searchTerm if needed
      //     });

      //     const result = await response.json();

      //     if (result.success) {
      //       setResultData((prevData) => ({
      //         ...prevData,
      //         documents: result.documents // Assuming result.documents is the array you want to store
      //       }));
      //       handleSuccess('Documents generated successfully!');
      //     } else {
      //       handleError(result.message);
      //     }
      //     } catch (err) {
      //       handleError(err);
      //     } finally {
      //       setGenerateLoading(false);
      //     }

      // };
      
      
  return (
    
    <>
    {!showResult ? (
        <div className={styles.casesearchform}>
          <div className={styles.casesearchformheading}>
            Drafting
          </div>

          <form className={styles.searchmodelform} onSubmit={handleSubmit}>
            <div className={styles.searchmodelinputgroup}>
              <label htmlFor="caseDetails">Write Case Details & Special Considrations</label>
              <textarea
                id="caseDetails"
                name="caseDetails"
                placeholder="Case Details..."
                value={formData.caseDetails}
                onChange={e => handleChange(e.target.value, 'caseDetails')}
                disabled={loading}
                required
              />
            </div>

            <div className={styles.searchmodelinputgroup}>
              <label htmlFor="act">Acts/Subsections</label>
              <input
                id="act"
                name="act"
                placeholder="Acts/Subsections..."
                value={formData.act}
                onChange={e => handleChange(e.target.value, 'act')}
                list="act-options"
                disabled={loading}
                style={{height: "40px"}}
                required
              />
                <datalist id="act-options">
                  {ActList.map((act) => (
                    <option key={act} value={act} />
                  ))}
                </datalist>
            </div>


            <div className={styles.searchmodelinputgroup}>
              <label htmlFor="doctype">Document Type</label>
              <Select
                id="doctype"
                name="doctype"
                mode="multiple"
                placeholder="Select Document Type"
                value={formData.doctype}
                onChange={(value) => handleChange(value, 'doctype')}
                disabled={loading}
                required
                style={{ width: '100%', minHeight: "40px" }}
              >
                {documentTypes.map((type) => (
                  <Option key={type} value={type}>
                    {type}
                  </Option>
                ))}
              </Select>
            </div>



            <div className={styles.casesearchsubmitbutton}>
              <button className=" btn btn-primary {styles.casesearchsubmitbutton}" type="submit" disabled={loading}>
              {loading ? 'Loading...' : 'Submit'}
              </button>
            </div>
          </form>
          <ToastContainer />
        </div>
      ) : (
        <div className={styles.resultcontainer}>
          <div className={styles.backButton} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center', width: '300px' }}>
              
              {/* <input
                type="text"
                placeholder="Doc Generator..."
                value={searchTerm}
                onChange={handleSearchChange}
                style={{ flex: 1, marginRight: '5px', padding: '5px' }}
                disabled={generateLoading}
              />

              <button onClick={handleSearch} style={{ padding: '5px 10px' }} disabled={generateLoading}>
              {generateLoading ? 'Generating...' : 'Generate'}
              </button> */}
              
            </div>

            <button onClick={handleBack} className="btn btn-primary">
              Back to Form
            </button>

          </div>

          <ResultForms Draftdocs={resultData} caseDetails={formData.caseDetails} acts={formData.act}/>

        </div>
      )}

      </>
    
  )
}

export default CaseSearchForm
