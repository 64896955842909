import React from 'react'
import { useNavigate } from 'react-router-dom';
import logo from "assets/logo512.png"

import { Flex, Menu } from 'antd'
import { UserOutlined, LoginOutlined, FileSearchOutlined, FileAddOutlined, HighlightOutlined, FileDoneOutlined} from '@ant-design/icons'
import { ToastContainer } from 'react-toastify';
import { handleSuccess } from 'Pages/Utils';


function Sidebar({ selectedMenuItem, setSelectedMenuItem }) {
  const navigate = useNavigate();

  const handleMenuClick = ({ key }) => {
    if (key === '6') {
      // Handle logout redirection
      localStorage.removeItem('token');
      localStorage.removeItem('loggedInUser');
      handleSuccess('User loggedout');
      setTimeout(()=>{
        navigate("/login");
      },1000)

    } else {
      // Handle other menu item clicks
      setSelectedMenuItem(key);
    }
  };

  return (
    <>
    <Flex align='center' justify='center'>
        <div className="db-logo">
        <img className="logo-image" src={logo} alt="Logo" /> 
        </div>    
    </Flex> 

    <Menu 
      mode='inline' 
      selectedKeys={[selectedMenuItem]} 
      className='bd-menu-bar'
      onClick={handleMenuClick} // Handle menu item clicks
      items={[
        {
          key:'1',
          icon: <UserOutlined />,
          label: 'Dashboard',
        },
        {
          key:'2',
          icon: <FileSearchOutlined />,
          label: 'Legal Research',
        },
        {
            key:'3',
            icon:<FileAddOutlined />,
            label: 'Drafting',
        },
        {
            key:'4',
            icon: <HighlightOutlined />,
            label: 'Data Extraction',
        },
        {
            key:'5',
            icon: <FileDoneOutlined />,
            label: 'Doc Review',
        },
        {
            key:'6',
            icon: <LoginOutlined />,
            label: 'Logout',
        },
      ]} 
    />
    <ToastContainer />
    
    </>
  )
}

export default Sidebar
