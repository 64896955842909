import React, { useState } from 'react';
import { List, Card, Button, Select, Input, Spin, Modal, Rate, Flex } from 'antd';
import { SaveOutlined, ArrowLeftOutlined, LoadingOutlined } from '@ant-design/icons';

import Style from './ResultForms.module.css'

const { Option } = Select;

function ResultForms({ Draftdocs, caseDetails, acts }) {
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [editableContent, setEditableContent] = useState('');
  const [loading, setLoading] = useState(false); // Loading state
  const [searchDisabled, setSearchDisabled] = useState(false); // To disable select and button
  const [feedbackVisible, setFeedbackVisible] = useState(false); // Feedback modal visibility
  const [rating, setRating] = useState(0); // Feedback rating
  const [comment, setComment] = useState(''); // Feedback comment
  const [downloadedDocuments, setDownloadedDocuments] = useState([]);


  // Function to handle document selection
  const handleSelectDocument = (document) => {
    setSelectedDocument(document);
    setSelectedOption(null); // Reset option on document change
    setEditableContent(''); // Set initial content to empty
  };

  // Function to go back to the list
  const handleBack = () => {
    setSelectedDocument(null);
    setEditableContent(''); // Clear the editable content
    setSelectedOption(null); // Clear selected option
  };

  // Function to call API and fetch document content
  const fetchDocumentContent = async () => {
    setLoading(true); // Start loading
    setSearchDisabled(true); // Disable select and button
    try {
      const url = process.env.REACT_APP_FCAPI
        ? `${process.env.REACT_APP_FCAPI}/Drafting/form_generater`
        : 'http://localhost:8080/Drafting/form_generater'; // Adjust this endpoint as needed

      const headers = {
        'Authorization': localStorage.getItem('token'),
        'Content-Type': 'application/json'
      };

      const response = await fetch(url, { 
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ selectedOption }) // Send the selected document name as payload
      });

      const result = await response.json();

      if (result.success) {
        setEditableContent(result.doc.document); // Assuming result.documents contains the document content
      } else {
        console.error('Error:', result.message); // Handle API error response
      }
    } catch (err) {
      console.error('Error fetching document content:', err); // Catch and log any errors
    } finally {
      setLoading(false); // Stop loading
      setSearchDisabled(false); // Re-enable select and button
    }
  };

  // Function to handle option selection
  const handleOptionChange = (value) => {
    setSelectedOption(value); // Set selected option
  };

  const handleDownloadClick = () => {
    setFeedbackVisible(true); // Show feedback form
  };
  
  ////////////////////////////////////////feedback form/////////////////////////////////

  const handleFeedbackSubmit = async () => {
    setFeedbackVisible(false); // Close feedback form
    handleDownload(); // Trigger the download after feedback
  
    const feedbackdocument = {
      catagories: selectedDocument.title, // Assuming 'Civil Pleadings' is the document type
      title: selectedOption, // The selected document title
      content: editableContent, // The document content
      feedback: rating, // User's rating
      feedbackComment: comment,
      caseDetails: caseDetails,
      acts: acts 
    };

    try {
      const url = process.env.REACT_APP_FCAPI
        ? `${process.env.REACT_APP_FCAPI}/feedback/drafting_doc`
        : 'http://localhost:8080/feedback/drafting_doc';
  
      const headers = {
        'Authorization': localStorage.getItem('token'),
        'Content-Type': 'application/json',
      };
  
      await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(feedbackdocument),
      });
  
    } catch (err) {
      console.error('Error creating document:', err);
    }

    // Append the downloaded document to the state
    setDownloadedDocuments((prevDocs) => {
      // Use the previous state to create a new array
      return [
        ...prevDocs, // Include all previous documents
        feedbackdocument, // Add the new document
      ];
    });

    console.log(downloadedDocuments);
  
    // Reset feedback form and state
    setRating(0);
    setComment('');
  };
  

  // Function to download the edited content as a .doc file
  const handleDownload = () => {
    const element = document.createElement('a');
    const content = `
      <html xmlns:v="urn:schemas-microsoft-com:vml"
            xmlns:o="urn:schemas-microsoft-com:office:office"
            xmlns:w="urn:schemas-microsoft-com:office:word"
            xmlns="http://www.w3.org/TR/REC-html40">
      <head>
        <meta charset="utf-8">
        <title>Document</title>
        <style>
          body {
            font-family: 'Times New Roman', serif;
            font-size: 11pt;
          }
        </style>
      </head>
      <body>${editableContent.replace(/\n/g, '<br>')}</body>
      </html>
    `;

    const file = new Blob([content], { type: 'application/msword' });
    element.href = URL.createObjectURL(file);
    element.download = `${selectedDocument.title}.doc`; // Save as .doc
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element); // Clean up
  };

  return (
    <div className={Style.resultform_Container}>
      {selectedDocument ? (
        <Card bordered={false} style={{ width: 'auto', height: '100%', margin: "0px 5% 0px 5%" }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
            <Button icon={<ArrowLeftOutlined />} type="primary" onClick={handleBack}>
              Back to List
            </Button>
            <h3 style={{ flexGrow: 1, textAlign: 'center', fontWeight: "700", fontSize: "20px" }}>{selectedDocument.title}</h3>
            <Button type="primary" icon={<SaveOutlined />} onClick={handleDownloadClick} disabled={!editableContent}>
              Download as .doc
            </Button>
          </div>

          <div style={{ marginBottom: '20px' }}>
            <h3 style={{fontSize: '15px', fontWeight: "700"}}>Select Document</h3>

            <Flex align="center" justify="space-between" gap="12px">
            <Select
              style={{ width: '100%' }}
              placeholder="Select a document"
              onChange={handleOptionChange} // Set selected option
              value={selectedOption}
              disabled={searchDisabled || loading} // Disable when loading or searchDisabled is true
            >
              {Draftdocs.documents[selectedDocument.title].map((content, index) => (
                <Option key={index} value={content}>
                  {content}
                </Option>
              ))}
            </Select>

            <Button
              type="primary"
              onClick={fetchDocumentContent}
              disabled={!selectedOption || searchDisabled} // Disable if no option selected or already searching
              style={{minWidth: "200px"}}
            >
              {loading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />} /> : 'Generate'}
            </Button>

             </Flex>
            <Input.TextArea
              value={editableContent}
              onChange={(e) => setEditableContent(e.target.value)}
              autoSize={{ minRows: 13, maxRows: 30 }}
              style={{ width: '100%', marginTop: '20px' }}
              disabled={!selectedOption || loading} 
            />
          </div>
        </Card>
      ) : (
        <div>
          <List
            grid={{ gutter: 8, column: 1 }}
            dataSource={Object.keys(Draftdocs.documents)}
            renderItem={(item) => (
              <List.Item style={{ marginBottom: '4px' }}>
                <Card
                  onClick={() => handleSelectDocument({ title: item })} // Select document
                  hoverable
                >
                  <Card.Meta title={item} />
                </Card>
              </List.Item>
            )}
          />
        </div>
      )}

      {/* Feedback Modal */}
      <Modal
        title="Feedback"
        open={feedbackVisible}
        onOk={handleFeedbackSubmit}
        onCancel={() => setFeedbackVisible(false)}
        okText="Submit"
        cancelText="Cancel"
        okButtonProps={{ disabled: rating === 0 }}
      >
        <div>
          <h3>Rate the Document</h3>
          <Rate onChange={(value) => setRating(value)} value={rating} />
        </div>
        <div style={{ marginTop: '20px' }}>
          <h3>Leave a Comment</h3>
          <Input.TextArea 
            rows={4} 
            value={comment}
            onChange={(e) => setComment(e.target.value)} 
            placeholder="Your feedback here..."
          />
        </div>
      </Modal>
    </div>
  );
}

export default ResultForms;
