import React, { useState } from 'react';
import logo from 'assets/synlex-logo.svg';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './Navbar.css'

function Navbar() {

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    const handleMenu = () => {
        setIsMenuOpen(prevState => !prevState); // Toggle the menu visibility
    };

    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const closeMenu = () => {
        setIsMenuOpen(false); // Close the menu
    };

    

    const handleLoginClick = () => {
        navigate('/login');
        closeMenu();
    };
    const handleSignUpClick = () => {
        navigate('/signup');
        closeMenu();
    };

    
    const handleWaitlistClick = (e) => {
    // Navigate to home page
    if (location.pathname !== '/home') {
        // Navigate to home page
        navigate('/', { replace: true });
        
        // After navigation, scroll to the contact section
        setTimeout(() => {
            const contactSection = document.getElementById('contact');
            if (contactSection) {
                window.scrollTo({
                    top: contactSection.offsetTop,
                    behavior: 'smooth'
                });
            }
        }, 100); // Slight delay to ensure navigation is processed
    } else {
        // If already on home page, scroll to the contact section immediately
        const contactSection = document.getElementById('contact');
        if (contactSection) {
            window.scrollTo({
                top: contactSection.offsetTop,
                behavior: 'smooth'
            });
        }
    }
      };
    return (
        <>
            <nav className='nav-container'>
                <Link to="/" className="brand-logo">
                    <img className="logo-image" src={logo} alt="Logo" /> 
                    <p>Synlex AI</p>
                </Link>
                <div className='nav-menu' >
                    <Link to="/" onClick={location.pathname === '/' ? handleScrollToTop : undefined} className="nav-link">Home</Link>
                    <Link to="/about" onClick={location.pathname === '/about' ? handleScrollToTop : undefined} className="nav-link">About</Link>
                    <p onClick={handleWaitlistClick} className="nav-link nav-waitlist">Waitlist</p>
                </div>

                <button className='btn btn-primary login-button' onClick={handleLoginClick}>
                    <span>Login</span>
                    <i className="fa-solid fa-arrow-right-to-bracket"></i>
                </button>
                <button className='btn btn-primary signup-button' onClick={handleSignUpClick}>
                    <span>Sign up</span>
                    <i className="fa-solid fa-arrow-right-to-bracket"></i>
                </button>

                
                <button className='button-toggle' onClick={handleMenu}>
                    <i className="fa-solid fa-bars"></i>
                </button>

                {/* Use Tailwind's conditional classes based on state */}
                <div className={`mobile-menu md:hidden bg-white inset-0 p-3 transition-transform transform ${isMenuOpen ? 'translate-x-0' : 'translate-x-full'}`}>
                    <div id="nav-bar" className='flex justify-between'>
                        <Link to="/" className="brand-logo">
                            <img className="logo-image" src={logo} alt="Logo" />
                            <p>Synlex AI</p>
                        </Link>
                        <button className='p-2' onClick={handleMenu}>
                            <i className="fa-solid fa-xmark"></i>
                        </button>
                    </div>
                    <div className='mt-6'>
                        <Link to="/" className='mobile-menu-link' onClick={closeMenu}>Home</Link>
                        <Link to="/about" className='mobile-menu-link' onClick={closeMenu}>About</Link>
                        <p className="mobile-menu-link" onClick={() => {closeMenu(); handleWaitlistClick();}}>Waitlist</p>
                    </div>
                    <div className='h-[1px] bg-gray-600'></div>
                    <button className='mobile-login-button btn btn-primary' onClick={handleLoginClick}>
                        <span>Login</span>
                        <i className="fa-solid fa-arrow-right-to-bracket"></i>
                    </button>
                    <button className='mobile-login-button btn btn-primary' onClick={handleSignUpClick}>
                        <span>Sign up</span>
                        <i className="fa-solid fa-arrow-right-to-bracket"></i>
                    </button>
                </div>
            </nav>
        </>
    );
}

export default Navbar;
