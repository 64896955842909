import React, { useState } from 'react';
import './SEResult.css';
import { Link } from 'react-router-dom';


function SEResult({ resultData }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [visibleCount, setVisibleCount] = useState(6);

if (!resultData) {
   return <div>No Data found</div>;
}

  const handleCardClick = (card) => {
    setSelectedCard(card);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedCard(null);
  };

  //no of card visible controller
  
  const loadMoreCards = () => {
    setVisibleCount((prevCount) => prevCount + 6); // Increase the count by 6
  };

  return (
    <div className="result-component">

      <div className="result-cards">
        {resultData.documents.slice(0, visibleCount).map((card, index) => (
          <div className='the-card' key={index} onClick={() => handleCardClick(card)}>
            <div className="card-title">{card.metadata.party1} <span>VS</span></div>
            <div className="card-title">{card.metadata.party2}</div>
            <div className="summury-ss">{card.content}</div>
            <div className="card-topic">
              <span>Year: {card.metadata.year}</span> <span>click for more details</span>
            </div>
          </div>
        ))}
      </div>
      {visibleCount < resultData.documents.length && (
        <div className="load-more-container">
          <button className="btn btn-primary load-more" onClick={loadMoreCards}>
            Load More
          </button>
        </div>
      )}

      {modalOpen && selectedCard && (
        <div className="modal-overlay" onClick={handleCloseModal}>

          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            
          <div className="model-title">

            <Link 
             to={`https://www.google.com/search?q=${encodeURIComponent(selectedCard.metadata.party1)}+vs+${encodeURIComponent(selectedCard.metadata.party2)}+${encodeURIComponent(selectedCard.metadata.year)}`}
             target="_blank"
             rel="noopener noreferrer">
              {selectedCard.metadata.party1} 
              &nbsp;&nbsp;
              <span>VS</span> 
              &nbsp;&nbsp;
              {selectedCard.metadata.party2} 
              &nbsp;&nbsp;<i className="fa-solid fa-up-right-from-square"></i>
            </Link>
          </div>
            
          <div className='modal-summary'>{selectedCard.content}</div>
          <div className="legalRefrences">{selectedCard.metadata.legal_refrences}</div>

          <div className='modal-card-detail'>
            <span>Year: </span>{selectedCard.metadata.year}
          </div>

            <button className="close-button" onClick={handleCloseModal}>Close</button>
          </div>
        </div>
      )}

    </div>
  );
}

export default SEResult;
