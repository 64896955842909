import React, { useState } from 'react'
import './Login.css'
import { useNavigate, Link } from 'react-router-dom'
import { handleError, handleSuccess } from 'Pages/Utils';
import { ToastContainer } from 'react-toastify';

function Login() {

  const [loginInfo, setLoginInfo] = useState({
    email: '',
    password: ''
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const {name, value} = e.target;
    const copyLoginInfo = { ...loginInfo };
    copyLoginInfo[name] = value;
    setLoginInfo(copyLoginInfo);
  }




  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    // You can now use the username and password state variables
    // console.log(loginInfo);

    // Add logic here to process the login, like making an API call
    try {
      
          
      const url = process.env.REACT_APP_FCAPI ? `${process.env.REACT_APP_FCAPI}/auth/login` : 'http://localhost:8080/auth/login';

      const response = await fetch(url, {
        method: "POST",
        headers: {
          'content-type' : 'application/json'
        },
        body: JSON.stringify(loginInfo)
      }) ;
      const result = await response.json();
      const { success, message, jwtToken, name, error} = result;
      if(success){
        handleSuccess(message);
        localStorage.setItem('token', jwtToken);
        localStorage.setItem('loggedInUser', name);
        setTimeout(() => {
          navigate('/Dashboard')
        }, 1000)
      }else if(error){
        const details = error?.details[0].message;
        handleError(details);
      }else if(!success){
        handleError(message);
      }
      // console.log(result);
    } catch (err) {
      handleError(err);
    }


    //clear the login form
    setLoginInfo({
      email: '',
      password: ''
    });
  };




  return (
    <div className='login-container'>

      <div className="login-form">

        <div className="login-header">
          Login
        </div>
        
        <form onSubmit={handleSubmit}>
          
          <div className="login-input-box">
            <input 
              type="email"
              name='email'
              placeholder='Email address' 
              value={loginInfo.email}
              onChange={handleChange}
              autoFocus
              autoComplete='true'
              required
            />
            <i className="fa-solid fa-user login-page-icon"></i>
          </div>
          
          <div className="login-input-box">
            <input 
              type="password" 
              name="password"
              placeholder='Password'
              value={loginInfo.password}
              onChange={handleChange} 
              autoComplete='true'
              required
            />
            <i className="fa-solid fa-lock login-page-icon"></i>
          </div>

          {/* <div className="remember-forgot">
          
            <label>
              <input type="checkbox" />Remember me
            </label>

            <Link to='/'>Forget password?</Link>
          
          </div> */}
        
          <button type='submit' className='btn btn-primary login-page-button'>Login</button>

          <div className="registration-link">
            <p>Don't have a account?</p>
            <Link to='/signup'>Register</Link>
          </div>

        </form>
        <ToastContainer />
      </div>

    </div>

  )
}

export default Login
