import { Flex } from 'antd'
import React from 'react'
import MainContent from './MainContent'
// import SideContent from './SideContent'

function DashboardContent({ setSelectedMenuItem }) {
  return (
    <div>
        <Flex gap='large'>
          <MainContent setSelectedMenuItem={ setSelectedMenuItem }/>
          {/* <SideContent /> */}
        </Flex>
    </div>
  )
}

export default DashboardContent
