import { Button, Card, Flex, Typography } from 'antd'
import React from 'react'
import './DashboardContent.css'



function MainContent({ setSelectedMenuItem }) {

  return (
    <div className='main-content-container' >
      <Flex vertical gap='2.3rem'>
        
        <Card className='main-content-card'>
          <Flex vertical gap='0px'>
              
              <Flex vertical align='flex-start' >
                  <Typography.Title level={2} strong>
                      Create New case
                  </Typography.Title>
              </Flex>

              <Flex gap='large'>
                  <Button type='primary' size='large' onClick={() => setSelectedMenuItem('2')}>Case Search</Button>
                  <Button type='primary' size='large' onClick={() => setSelectedMenuItem('3')}>Drafting</Button>
              </Flex>

          </Flex>
        </Card>

        
      </Flex>
    </div>
  )
}

export default MainContent
