import React, { useState } from 'react';

import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate
} from "react-router-dom";

//components
import Home from './Pages/Home/index.jsx'
import Navbar from './Pages/Home/Navbar/Navbar.jsx';
import About from './Pages/About/About.jsx';
import Login from './Pages/Login/Login.jsx';
import SignUp from './Pages/SignUp/SignUp.jsx';
import Demo from './Pages/Demo/index.jsx';

import Dashboard from './Pages/Dashboard/Index.jsx'
// import SEResult from 'Pages/Dashboard/ContentComponents/DashboardContent/ResultData/SEResult';


import 'react-toastify/ReactToastify.css'
import RefreshHandler from 'RefreshHandler';
function App() {
  const location = useLocation();
  // Hide the Navbar
  // const showNavbar = !['/demo', '/result', '/dashboard'].includes(location.pathname);
  
  // Check for path exclusions check lower upper all cases of path ///Hide the Navbar
  const normalizedPath = location.pathname.toLowerCase();
  const showNavbar = !['/demo', '/result', '/dashboard'].map(path => path.toLowerCase()).includes(normalizedPath);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  //private routes
  const PrivateRoute = ({element}) => {
    return isAuthenticated ? element : <Navigate to='/login' />
  }


  return (
    <div>
      {showNavbar && <Navbar />}
      <RefreshHandler setIsAuthenticated={setIsAuthenticated} />
      <Routes>
        <Route path="/" element={<Navigate to ='/home' />} />
        <Route path='/home' element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path='/demo' element={<Demo />} />
        <Route path='/login' element={<Login />} />
        <Route path='/signup' element={<SignUp />} />
        {/* <Route path="/result" element={<SEResult />} /> */}
        <Route path="/dashboard" element={<PrivateRoute element={<Dashboard />} />} />
        <Route path="*" element={<div>404 Not Found</div>} />
      </Routes>
    </div>
  );
}

//wrap up the App in BrowserRouter before Export
export default function AppWithRouter() {
  return (
    <Router>
      <App />
    </Router>
  );
}
