import React, { useState } from 'react'

import styles from './LegalSearchForm.module.css'

import { handleError, handleSuccess } from 'Pages/Utils';
import { ToastContainer } from 'react-toastify';
import SEResult from '../ResultData/SEResult';

import actList from 'assets/acts_list.json';

function CaseSearchForm() {

  const [resultData, setResultData] = useState(null);
  const [formVisible, setFormVisible] = useState(true);
  const [loading, setLoading] = useState(false)

    const [formData, setFormData] = useState({
        caseDetails: '',
        act: '',
        specialConsiderations: ''
      });

    

      const handleChange = async (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value
        }));


      };

      
      const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        
        //API CALL
        try {
          const url = process.env.REACT_APP_FCAPI ? `${process.env.REACT_APP_FCAPI}/legalSearch/add` : 'http://localhost:8080/legalSearch/add';
          
          const headers = {
            'Authorization': localStorage.getItem('token'),
            'Content-Type': 'application/json'
        };
        
        const body = JSON.stringify({
          caseDetails: formData.caseDetails,
          act: formData.act,
        });

          const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: body
        });
        const result = await response.json(); 
    
        if (result.success) {
          handleSuccess(result.message);
          // Clear the form fields
          setFormData({
            caseDetails: '',
            act: ''
          });
          
          setResultData(result.doc);
          setFormVisible(false);

         } else {
          handleError(result.message);
        }

        } catch (err) {
           handleError(err);
        } finally {
          setLoading(false);
        }
      }

      const toggleFormVisibility = () => {
        setFormVisible(!formVisible);
      };

      const filteredActs = actList.filter(act =>
        act.toLowerCase().includes(formData.act.toLowerCase())
      );
      
  return (
    
    <>
        {formVisible ? (
        <div className={styles.casesearchform}>
          <div className={styles.casesearchformheading}>
            Legal Research
          </div>

          <form className={styles.searchmodelform} onSubmit={handleSubmit}>
            <div className={styles.searchmodelinputgroup}>
              <label htmlFor="caseDetails">Write Case Details</label>
              <textarea
                id="caseDetails"
                name="caseDetails"
                placeholder="Case Details..."
                value={formData.caseDetails}
                onChange={handleChange}
                required
              />
            </div>

            <div className={styles.searchmodelinputgroup}>
              <label htmlFor="act">Acts/Subsections</label>
              <input
                id="act"
                name="act"
                placeholder="Acts/Subsections..."
                value={formData.act}
                list="act-options"
                onChange={handleChange}
                required
              />
              <datalist id="act-options">
                {filteredActs.map((act, index) => (
                  <option key={index} value={act} />
                ))}
              </datalist>
            </div>

            <div className={styles.casesearchsubmitbutton}>
              <button className=" btn btn-primary {styles.casesearchsubmitbutton}" type="submit" disabled={loading}>
              {loading ? 'Loading...' : 'Submit'}
              </button>
            </div>
          </form>
          <ToastContainer />
        </div>
      ) : (
        <div className={styles.toggleButtonContainer}>
          <button className="btn btn-opposite {styles.toggleButton}" onClick={toggleFormVisibility}>
            Show Form
          </button>
        </div>
      )}

      {resultData && (
        <div className={styles.resultcontainer}>
            <SEResult resultData={resultData} />
        </div>
      )}

      </>
    
  )
}

export default CaseSearchForm
