import React from 'react'
import DraftingForm from './DraftingForm/DraftingForm'

function DraftingContent() {
  return (
    <div>
      <DraftingForm />
    </div>
  )
}

export default DraftingContent
